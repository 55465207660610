import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import firebase from './config/firebase';
import Frame from './Components/Frame';
import NotFound from './Components/NotFound';
import { useTranslation } from 'react-i18next';

const Ticket = ({ props }) => {
    const { id } = useParams()
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [doc, setDoc] = useState(null);
    const [brand, setBrand] = useState(null)

    const { t } = useTranslation();

    useEffect(() => {
        if (!doc) {
            firebase.firestore().doc(`requests/${id}`)
                .onSnapshot((docRef) => {
                    if (docRef.exists) {
                        let data = docRef.data();

                        setDoc(docRef);
                        setData({
                            ...data.query,
                            response: data.response || {}
                        });
                    }
                    setLoaded(true);
                }, err => {
                    console.log(err);
                    setLoaded(true);
                    setDoc(null);
                    setData(null);
                })
        }
    }, [id, doc])
    useEffect(() => {
        if (!brand && data && data.brand) {
            firebase.firestore().doc(`brands/${data.brand}`).get()
                .then((docRef) => {
                    if (docRef.exists) {
                        setBrand(docRef.data())
                    } else {
                        setBrand({
                            logo: null,
                            name: null
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [brand, data])


    const handleChange = (status) => {
        if (!doc) return;
        let endpoint = 'https://europe-west3-dg-verification.cloudfunctions.net/response';
        if (window.location.hostname === 'localhost') {
            endpoint = 'http://localhost:5001/dg-verification/europe-west3/response';
        }

        setSubmitting(true);

        let data = {
            id: doc.id,
            status,
        }

        fetch(endpoint, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => {
                setSubmitting(false);
                if (response.status !== "success") {
                    alert(t('submit.failed'));
                    console.log(response);
                }
            }).catch((err) => {
                console.error("Error adding document", err);
            })
    }

    const handleAccept = () => {
        handleChange(true);
    }
    const handleDecline = () => {
        handleChange(false);
    }

    if (!loaded) {
        return <Frame><CircularProgress /></Frame>;
    }

    if (data === null) {
        return <NotFound />;
    }

    return (
        <Frame>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        {brand && brand.logo &&
                            <Box style={{ textAlign: 'center', marginBottom: 20 }}>
                                <img src={brand.logo} alt={`${brand.name} logo`} style={{ width: '50%' }} />
                            </Box>
                        }
                        <Typography variant="body1">
                            {t('request.title')}
                        </Typography>
                        <Typography variant="body2">
                            {t('request.desc1', { ticketId: data.ticket })}
                        </Typography>
                        {data.response.status === undefined ?
                            <NoResponse
                                data={data}
                                submitting={submitting}
                                handleDecline={handleDecline}
                                handleAccept={handleAccept} />
                            :
                            <Responded data={data} />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Frame>
    );
}

const Responded = ({ data }) => {
    const { t } = useTranslation();
    return (
        <>
            <Grid item xs={12} style={{
                padding: 10,
                background: '#eee',
                border: '1px #ddd solid',
                borderRadius: 3,
                marginTop: 10,
                marginBottom: 20
            }}>
                <Typography variant="body2">
                    {t('response.text', {
                        status: (data.response.status ? t('response.APPROVED') : t('response.REJECTED')),
                        timestamp: formatDate(data.response.updated_at.toDate())
                    }
                    )}
                </Typography>
                <Typography variant="body2">
                    {data.message}
                </Typography>
            </Grid>
            <Typography variant="body2">
                {data.response.status ?
                    t('response.status.approved')
                    :
                    t('response.status.rejected')
                }
            </Typography>
        </>
    )
}

const NoResponse = ({ data, submitting, handleDecline, handleAccept }) => {
    const { t } = useTranslation();
    if (submitting) {
        return <Grid item xs={12} style={{ textAlign: 'center' }}><CircularProgress /></Grid>
    }
    return (
        <>
            <Typography variant="body2">
                {t('request.desc2')}
            </Typography>
            <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap', background: '#eee', border: '1px #ddd solid', padding: 10 }}>
                {data.message}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={6} style={{ textAlign: 'center', marginTop: 20 }}>
                    <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={handleDecline}
                        disabled={submitting}
                    >
                        {t('request.reject')}
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center', marginTop: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        onClick={handleAccept}
                        disabled={submitting}
                    >
                        {t('request.approve')}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

const prefixZero = (val) => {
    if (val < 10) {
        val = "0" + val;
    }
    return val;
}
const formatDate = (date) => {
    let y = date.getFullYear()
    let m = prefixZero(date.getMonth());
    let d = prefixZero(date.getDate());

    let h = prefixZero(date.getHours());
    let i = prefixZero(date.getMinutes());

    return `${d}.${m}.${y} ${h}:${i}`;
}

export default Ticket;