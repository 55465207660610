import { Container, Grid, Paper } from '@material-ui/core';
import React from 'react';

const Frame = ({ children }) => {
    return (
        <Container
            maxWidth="xs"
            style={{ height: '100vh' }}
        >
            <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ height: '100vh' }}
            >
                <Paper style={{ padding: 20 }}>
                    {children}
                </Paper>
            </Grid>
        </Container>
    );
}

export default Frame;