import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: {
            "nb": ["no"],
            "nn": ["no"],
            "default": ["en"]
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    request: {
                        title: "Approve changes to your account or services",
                        desc1: "We refer to inquiry #{{ticketId}} where we require a verification of the contact person on the customer account before we implement the change.",
                        desc2: "Please reject or approve the following changes",
                        reject: "Reject",
                        approve: "Approve",
                    },
                    response: {
                        text: "The following changes was {{status}} at {{timestamp}}:",
                        APPROVED: "APPROVED",
                        REJECTED: "REJECTED",
                        at: "at",
                        status: {
                            approved: "The changes you have approved will be processed within our opening hours.",
                            rejected: "We have received your notification that the changes are not desired at this time.",
                        },
                    },
                    submit: {
                        failed: "An error has occurred, please try again",
                    },
                    notFound: "Nothing to see here",
                }
            },
            no: {
                translation: {
                    request: {
                        title: "Godkjenn endring på ditt kundeforhold eller tjeneste",
                        desc1: "Vi viser til henvendelse #{{ticketId}} hvor vi krever en verifikasjon av kontaktperson på kundeforholdet før vi gjennomfører endringen.",
                        desc2: "Vennligst avvis eller godkjenn følgende endringer:",
                        reject: "Avvis",
                        approve: "Godkjenn",
                    },
                    response: {
                        text: "Følgende endringer ble {{status}} {{timestamp}}:",
                        APPROVED: "GODKJENT",
                        REJECTED: "AVVIST",
                        status: {
                            approved: "Endringene du har godkjent vil bli behandlet innenfor våre åpningstider.",
                            rejected: "Vi har mottatt din melding om at endringene ikke ønskes foretatt på nåværende tidspunkt.",
                        },
                    },
                    submit: {
                        failed: "En feil har oppstått, vennligst prøv på nytt",
                    },
                    notFound: "Nothing to see here",
                }
            }
        }
    });

export default i18n;