import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Frame from './Frame';

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <Frame>
            <Typography variant="body1">
                {t('notFound')}
            </Typography>
        </Frame>
    );
}

export default NotFound;