const firebaseConfig = {
    apiKey: "AIzaSyA31qr16lEb7GNp8HG5ETIDD8_M9zzHQCY",
    authDomain: "dg-verification.firebaseapp.com",
    projectId: "dg-verification",
    storageBucket: "dg-verification.appspot.com",
    messagingSenderId: "515729799268",
    appId: "1:515729799268:web:5cda31b146c6731dee8771",
    measurementId: "G-ELP7NKFBEM"
};

export default firebaseConfig;